import React from 'react'
import Navbar from '../components/Navbar'
import LandingPage from '../components/LandingPage'
import LandingPage2 from '../components/LandingPage2'
import Footer from '../components/Footer'


function Home() {
    return (
        <div>
              <Navbar/>
              <LandingPage/>
              <LandingPage2/>
              <Footer/>

        </div>
    )
}

export default Home
